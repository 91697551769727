<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12" class="form-for-textarea">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                    <ValidationProvider name="Memo No"  vid="delivery_no" rules="required">
                      <b-form-group
                          class="row"
                          label-cols-sm="2"
                          label-for="delivery_no"
                          slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('fertilizerSales.memo_no')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="delivery_no"
                        v-model="formData.delivery_no"
                        :state="errors[0] ? false : (valid ? true : null)"
                        @keyup="getMemoData(formData.delivery_no)"
                        class="col-sm-4"
                      ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        <div class="invalid-feedback d-block" v-if="msg">
                          {{ msg }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div v-if="pos">
                        <div class="row" style="border-collapse: collapse;border: 1px solid black">
                        <div class="col-lg-6">
                            <b-table-simple class="mt-3" hover small bordered caption-top responsive>
                              <b-tbody>
                                  <b-tr>
                                  </b-tr>
                                  <b-tr>
                                  <th colspan="4">{{ $t('globalTrans.date') }}</th>
                                  <th class="text-center">:</th>
                                  <th colspan="4">{{ formData.sell_date | dateFormat }}</th>
                                  </b-tr>
                                  <b-tr>
                                  <th colspan="4">{{ $t('fertilizerSales.memo_no') }} </th>
                                  <th class="text-center">:</th>
                                  <th colspan="4">{{ $n(formData.memo_no) }}</th>
                                  </b-tr>
                                  <b-tr>
                                    <th colspan="4">{{ $t('fertilizerSales.dealer_name') }}</th>
                                    <th class="text-center">:</th>
                                    <th colspan="4">{{ $i18n.locale === 'en' ? formData.dealer_name : formData.dealer_name_bn }}</th>
                                  </b-tr>
                                  <b-tr>
                                    <th colspan="4">{{ $t('fertilizerSales.sales_center') }}</th>
                                    <th class="text-center">:</th>
                                    <th colspan="4">{{ formData.sell_center }}</th>
                                  </b-tr>
                                  <b-tr>
                                  <th colspan="4">{{ $t('fertilizerSales.godown') }} </th>
                                  <th class="text-center">:</th>
                                  <th colspan="4">{{ $i18n.locale === 'en' ? formData.godown_name : formData.godown_name_bn }}</th>
                                  </b-tr>
                                  <b-tr>
                                  <th colspan="4">{{ $t('fertilizerSales.dealer_code') }}</th>
                                  <th class="text-center">:</th>
                                  <th colspan="4">{{ $n(formData.dealer_code) }}</th>
                                  </b-tr>
                                  <b-tr>
                                  <th colspan="4">{{ $t('fertilizerSales.trade_name') }}</th>
                                  <th class="text-center">:</th>
                                  <th colspan="4">{{ $i18n.locale === 'en' ? formData.trade_name : formData.trade_name_bn  }}</th>
                                  </b-tr>
                                  <b-tr>
                                  </b-tr>
                                  <b-tr>
                                  <th colspan="4">{{ $t('fertilizerSales.dealer_licence') }}</th>
                                  <th class="text-center">:</th>
                                  <th colspan="4">{{ $n(formData.licence_no) }}</th>
                                  </b-tr>
                                  <b-tr>
                                  <th colspan="4">{{ $t('fertilizerSales.issue_date') }}</th>
                                  <th class="text-center">:</th>
                                  <th colspan="4">{{ formData.issue_date | dateFormat }}</th>
                                  </b-tr>
                                  <b-tr style="padding-bottom: 50px">
                                  <th colspan="4">{{ $t('globalTrans.district') }}</th>
                                  <th class="text-center">:</th>
                                  <th colspan="4">{{ getDistrictName(formData.district_id) }}</th>
                                  </b-tr>
                                  <b-tr style="padding-bottom: 50px">
                                    <th colspan="4">{{ $t('globalTrans.upazila') }}</th>
                                    <th class="text-center">:</th>
                                    <th colspan="4">{{ $i18n.locale === 'en' ? formData.upazila : formData.upazila_bn }}</th>
                                  </b-tr>
                              </b-tbody>
                            </b-table-simple>
                        </div>
                        <div class="col-lg-6">
                            <b-table-simple class="mt-3" hover small bordered caption-top responsive>
                            <b-tbody>
                            <b-tr>
                              <th colspan="4" class="text-center">{{ $t('fertilizerSales.dealerAllocationPurchaseRestAmountInformation') }}</th>
                            </b-tr>
                            <b-tr>
                              <th class="text-center">{{ $t('fertilizerSales.fertilizer') }}</th>
                              <th class="text-right">{{ $t('fertilizerSales.allocated_amount') }}</th>
                              <th class="text-right">{{ $t('fertilizerSales.purchase_amount') }}</th>
                              <th class="text-right">{{ $t('fertilizerSales.rest_amount') }}</th>
                            </b-tr>
                            <tr v-for="(pay,i) in payHistoriesList" :key="i">
                              <td class="text-center"> {{ $i18n.locale === 'bn' ? pay.fertilizer_name_bn : pay.fertilizer_name }}</td>
                              <td class="text-right"> {{ $n(pay.allocation_amount) }}</td>
                              <td class="text-right"> {{ $n(pay.purchase_amount) }}</td>
                              <td class="text-right"> {{ $n(pay.rest_amount) }}</td>
                            </tr>
                            </b-tbody>
                        </b-table-simple>
                        </div>
                        <div class="col-lg-12">
                          <div class="col text-right">
                            <span class="text-right text-dark font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                          </div>
                        </div>
                        <b-table-simple class="mt-3" hover small bordered caption-top responsive>
                            <b-thead>
                                <b-tr>
                                <th rowspan="2" class="text-center">{{ $t('fertilizerSales.fertilizer') }}</th>
                                <th rowspan="2" class="text-center">{{ $t('fertilizerSales.source_country') }}</th>
                                <th colspan="2" class="text-center">{{ $t('fertilizerSales.purchase_amount') }}</th>
                                <th rowspan="2" class="text-center">{{ $t('fertilizerSales.rate_per_ton') }}</th>
                                <th rowspan="2" class="text-center">{{ $t('fertilizerSales.ferti_purchase_price') }}</th>
                                <th colspan="4" class="text-center">{{ $t('fertilizerSales.money_recieve') }}</th>
                                <th rowspan="2" class="text-center">{{ $t('fertilizerSales.comment') }}</th>
                            </b-tr>
                            <b-tr>
                                <th class="text-center">{{ $t('fertilizerSales.amountBag') }}</th>
                                <th class="text-center">{{ $t('fertilizerSales.amount_ton') }}</th>
                                <th class="text-center">{{ $t('fertilizerSales.pay_order_no') }}</th>
                                <th class="text-center">{{ $t('globalTrans.date') }}</th>
                                <th class="text-center">{{ $t('fertilizerSales.bank') }} {{' & '}} {{ $t('fertilizerSales.branch') }}</th>
                                <th class="text-center">{{ $t('fertilizerSales.amountBag') }}</th>
                            </b-tr>
                            <b-tr>
                                <th class="text-center">{{ $n(1) }}</th>
                                <th class="text-center">{{ $n(2) }}</th>
                                <th class="text-center">{{ $n(3) }}</th>
                                <th class="text-center">{{ $n(4) }}</th>
                                <th class="text-center">{{ $n(5) }}</th>
                                <th class="text-center">{{ $n(6) }}</th>
                                <th class="text-center">{{ $n(7) }}</th>
                                <th class="text-center">{{ $n(8) }}</th>
                                <th class="text-center">{{ $n(9) }}</th>
                                <th class="text-center">{{ $n(10) }}</th>
                                <th class="text-center">{{ $n(11) }}</th>
                            </b-tr>
                            </b-thead>
                            <b-tbody>
                            <b-tr>
                                <td class="text-center">{{ $i18n.locale === 'bn' ? formData.fertilizer_id_bn : formData.fertilizer_id }}</td>
                                <td class="text-center"> {{ formData.source_country }} </td>
                                <td class="text-center"> {{ formData.amount_of_bags }} </td>
                                <td class="text-center"> {{ $n(formData.quantity, { useGrouping: false }) }} </td>
                                <td class="text-center"> {{ $n(formData.unit_price, { useGrouping: false }) }} </td>
                                <td class="text-center"> {{ $n(formData.total_price, { useGrouping: false }) }} </td>
                                <td class="text-center">
                                  <span v-for="(item, index) in payOrdersList" :key="index"> {{ item.pay_order_no }} <br> </span>
                                </td>
                                <td class="text-center">
                                  <span v-for="(item, index) in payOrdersList" :key="index"> {{ item.pay_order_date | dateFormat }} <br> </span>
                                </td>
                                <td class="text-center">
                                  <span v-for="(item, index) in payOrdersList" :key="index"> {{ getBankName(item.bank_id) }} <br> </span>
                                </td>
                                <td class="text-center">
                                  <span v-for="(item, index) in payOrdersList" :key="index"> {{ item.amount }} <br> </span>
                                </td>
                                <td> {{ formData.comments }} </td>
                            </b-tr>
                            <tr>
                                <th colspan="5"></th>
                                <th class="text-right">{{ $t('globalTrans.total') }}:</th>
                                <th> {{ formData.total_price }} </th>
                                <th colspan="4"></th>
                            </tr>
                            </b-tbody>
                        </b-table-simple>
                        <label>{{ $t('fertilizerSales.current_stock') }}: {{ formData.currentStockAmount }} ({{ $t('fertilizerSales.ton') }}) </label>
                        </div>
                        <div class="row mt-3">
                        <div class="col-lg-6">
                            <ValidationProvider name="Delivery Date"  vid="delivery_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="delivery_date"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('fertilizerSales.delivery_date')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-input
                                class="fromDate"
                                v-model="formData.delivery_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input> -->
                                <flat-pickr class="form-control"
                                v-model="formData.delivery_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></flat-pickr>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-lg-6">
                            <ValidationProvider name="Comment"  vid="comment" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="comment"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('fertilizerSales.comment')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="comment"
                            v-model="formData.comment"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { carryingAgentFinalProTransferAgent, carryingAgentFinalProWise, fertilizerDeliveryStore, carryingAgentFinalProUpdate, fertilizerDeliveryData } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
    //   const tmp = this.getFormData()
    //   this.formData = tmp
    const tmp = this.getFormData()
      const temps = Object.assign(this.formData, tmp)
      this.formData = temps
      this.getProgramData(this.formData.program_no)
      this.getDistrict(this.formData.godown_infos_id)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        godown_infos_id: 0,
        district_id: '',
        program_no: '',
        transport_agent_id: 0,
        p_id: '',
        amount: '',
        amount_of_bags: '',
        amount_of_bag: '',
        fertilizer_id: '',
        fiscal_year_id: '',
        godown_name: '',
        godown_name_bn: '',
        dealer_name: '',
        dealer_name_bn: '',
        dealer_code: '',
        memo_no: '',
        sales_center_id: '',
        sell_date: '',
        licence_no: '',
        issue_date: '',
        sell_center: '',
        sell_center_bn: '',
        comments: '',
        comments_bn: '',
        total_price: '',
        unit_price: '',
        quantity: '',
        source_country: '',
        trade_name: ''

      },
      pos: false,
      msg: '',
      totalAllocatedAmount: 0,
      totalAvailableAmount: 0,
      warehouseList: [],
      districtList: [],
      agentList: [],
      payHistoriesList: [],
      payOrdersList: []
    }
  },
  computed: {
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    dealerBasicList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.dealerBasicList.filter(item => item.status === 1)
    },
    salesCenterList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.salesCenterList.filter(item => item.status === 1)
    },
    portList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    importCountryList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    'formData.godown_infos_id': function (newVal, oldVal) {
      this.districtList = this.getDistrict(newVal)
    },
    'formData.transport_agent_id': function (newVal, oldVal) {
      this.setTransferableAmount(newVal)
    }
  },
  methods: {
    getBankName (Id) {
      const bank = this.$store.state.commonObj.bankObj.bankList.find(doc => doc.value === Id)
      if (typeof bank === 'undefined') {
        return ''
      } else {
        return this.$i18n.locale === 'bn' ? bank.text_bn : bank.text_en
      }
    },
    getDistrictName (Id) {
      const bank = this.$store.state.commonObj.districtList.find(doc => doc.value === Id)
      if (typeof bank === 'undefined') {
        return ''
      } else {
        return this.$i18n.locale === 'bn' ? bank.text_bn : bank.text_en
      }
    },
    multiplier (idd) {
       this.formData.amount_of_bag = idd * 20
    },
    setTransferableAmount (agentId) {
      var ids = 0
      if (this.id) {
        ids = this.formData.porgram_id
      } else {
        ids = this.formData.p_id
      }
      const params = Object.assign({}, { program_id: ids, transport_agent_id: this.formData.transport_agent_id })
      RestApi.getData(seedFertilizerServiceBaseUrl, carryingAgentFinalProTransferAgent, params).then(response => {
          this.formData.amount = response.data === 'null' ? '0' : response.data.amount
          this.formData.amount_of_bags = this.formData.amount * 20
      })
    },
    getDistrict (disID) {
      const params = Object.assign({}, { program_name: this.formData.program_no })
      RestApi.getData(seedFertilizerServiceBaseUrl, carryingAgentFinalProWise, params).then(response => {
          const test = response.data.godowns.filter(item => item.id === disID)
          const test2 = test.map(item => {
            const districtList = this.$store.state.commonObj.districtList.filter(district => district.value === item.district_id)
          this.formData.district_id = districtList[0].value
            if (this.$i18n.locale === 'bn') {
              return { text: districtList[0].text_bn, value: districtList[0].value }
            } else {
              return { text: districtList[0].text_en, value: districtList[0].value }
            }
          })
          this.districtList = test2
      })
    },
    getProgramData (programNo) {
      const params = Object.assign({}, { program_name: programNo })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(seedFertilizerServiceBaseUrl, carryingAgentFinalProWise, params).then(response => {
        if (response.success) {
          this.formData.p_id = response.data.program.p_id
          this.formData.fertilizer_id = response.data.program.fertilizer_id
          this.formData.fiscal_year_id = response.data.program.fiscal_year_id
          this.warehouseList = response.data.godowns.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.name_bn, value: item.id }
            } else {
              return { text: item.name, value: item.id }
            }
          })
          this.agentList = response.data.agents.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.company_agent_name_bn, value: item.id }
            } else {
              return { text: item.company_agent_name, value: item.id }
            }
          })
          }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
       return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${carryingAgentFinalProUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, fertilizerDeliveryStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getMemoData (memoNo) {
      // const params = Object.assign({}, this.search, { memo_no: memoNo })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(seedFertilizerServiceBaseUrl, `${fertilizerDeliveryData}/${memoNo}`).then(response => {
          if (response.success) {
              this.getCustomDataList(response.data)
              this.pos = true
              this.msg = ''
              // this.paginationData(response.data)
          } else {
              this.pos = false
              this.msg = response.message
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
     getCustomDataList (data) {
        const godownObj = this.godownInfoList.find(godownItem => godownItem.value === data.pointOfSale.from_godown_id)
        const salesCenterObj = this.salesCenterList.find(saleItem => saleItem.value === data.pointOfSale.sales_center_id)
        const fertilizerObj = this.fertilizerNameList.find(ferItem => ferItem.value === data.pointOfSale.fertilizer_id)
        const upazilaList = this.$store.state.commonObj.upazilaList.find(item => item.value === data.pointOfSale.dealer.dealer_details.per_upazilla_id)
        // const dealerObj = this.dealerBasicList.find(godownItem => godownItem.value === data.pointOfSale.dealer_id)
        this.formData.currentStockAmount = data.currentStockAmount
        this.formData.memo_no = data.pointOfSale.memo_no
        this.formData.dealer_name = data.pointOfSale.dealer.name
        this.formData.dealer_name_bn = data.pointOfSale.dealer.name_bn
        this.formData.dealer_code = data.pointOfSale.dealer.dealer_code
        this.formData.issue_date = data.pointOfSale.dealer.licence_issue_date
        this.formData.licence_no = data.pointOfSale.dealer.licence_number
        this.formData.district_id = data.pointOfSale.dealer.dealer_details.per_district_id
        this.formData.sell_date = data.pointOfSale.sell_date
        this.formData.trade_name = data.pointOfSale.dealer.trade_name
        this.formData.trade_name_bn = data.pointOfSale.dealer.trade_name_bn
        this.formData.source_country = data.pointOfSale.source_country
        this.formData.quantity = data.pointOfSale.quantity
        this.formData.amount_of_bags = data.pointOfSale.quantity * 20
        this.formData.total_price = data.pointOfSale.total_price
        this.formData.unit_price = data.pointOfSale.unit_price
        this.formData.comments = data.pointOfSale.comments
        // this.formData.fertilizer_id = data.pointOfSale.fertilizer_id
        if (typeof godownObj !== 'undefined') {
          this.formData.godown_name = godownObj.text_en
          this.formData.godown_name_bn = godownObj.text_bn
        } else {
          this.formData.godown_name = ''
          this.formData.godown_name_bn = ''
        }
        if (typeof salesCenterObj !== 'undefined') {
          this.formData.sell_center = salesCenterObj.text_en
          this.formData.sell_center_bn = salesCenterObj.text_bn
        } else {
          this.formData.sell_center = salesCenterObj
          this.formData.sell_center_bn = ''
        }
        if (typeof upazilaList !== 'undefined') {
          this.formData.upazila = upazilaList.text_en
          this.formData.upazila_bn = upazilaList.text_bn
        } else {
          this.formData.upazila = salesCenterObj
          this.formData.upazila_bn = ''
        }
        if (typeof fertilizerObj !== 'undefined') {
          this.formData.fertilizer_id = fertilizerObj.text_en
          this.formData.fertilizer_id_bn = fertilizerObj.text_bn
        } else {
          this.formData.fertilizer_id = ''
          this.formData.fertilizer_id_bn = ''
        }
        // this.payHistoriesList = data.pointOfSale.pay_histories
        const payHistoriesList = data.pointOfSale.pay_histories.map(item => {
          const fertiListObj = this.fertilizerNameList.find(ferItem => ferItem.value === item.fertilizer_id)
          const fertilizerData = {
            fertilizer_name: typeof fertiListObj !== 'undefined' ? fertiListObj.text_en : '',
            fertilizer_name_bn: typeof fertiListObj !== 'undefined' ? fertiListObj.text_bn : ''
          }
          return Object.assign({}, item, fertilizerData)
        })
        this.payHistoriesList = payHistoriesList
        this.payOrdersList = data.pointOfSale.pay_orders

        // if (typeof dealerObj !== 'undefined') {
        //   this.formData.dealer_name = dealerObj.text_en
        //   this.formData.dealer_name_bn = dealerObj.text_bn
        // } else {
        //   this.formData.dealer_name = ''
        //   this.formData.dealer_name_bn = ''
        // }
    }
  }
}
</script>
