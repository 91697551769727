export const testApi = '/test'

export const agentList = '/fertilizer/config/agents/list'
export const agentStore = '/fertilizer/config/agents/store'
export const agentUpdate = '/fertilizer/config/agents/update'
export const agentToggleStatus = '/fertilizer/config/agents/toggle-status'
export const agentDestroy = '/fertilizer/config/agents/destroy'

// ministry allocation api
export const ministryAllocationList = '/fertilizer/sales/ministry-allocations/list'
export const ministryAllocationStore = '/fertilizer/sales/ministry-allocations/store'
export const ministryAllocationUpdate = '/fertilizer/sales/ministry-allocations/update'
export const ministryAllocationFinalSave = '/fertilizer/sales/ministry-allocations/final-save'
export const ministryAllocationDestroy = '/fertilizer/sales/ministry-allocations/destroy'
export const ministryAllocationShow = '/fertilizer/sales/ministry-allocations/show/'

// badc allocation api
export const badcAllocationList = '/fertilizer/sales/badc-allocations/list'
export const badcAllocationStore = '/fertilizer/sales/badc-allocations/store'
export const badcAllocationUpdate = '/fertilizer/sales/badc-allocations/update'
export const badcAllocationFinalSave = '/fertilizer/sales/badc-allocations/final-save'
export const badcAllocationDestroy = '/fertilizer/sales/badc-allocations/destroy'
export const badcAllocationShow = '/fertilizer/sales/badc-allocations/show/'
export const getMinistryAllocation = '/fertilizer/sales/badc-allocations/get-ministry-allocation'

// dealer allocation api
export const dealerAllocationList = '/fertilizer/sales/dealer-allocations/list'
export const dealerAllocationStore = '/fertilizer/sales/dealer-allocations/store'
export const dealerAllocationUpdate = '/fertilizer/sales/dealer-allocations/update'
export const dealerAllocationFinalSave = '/fertilizer/sales/dealer-allocations/final-save'
export const dealerAllocationDestroy = '/fertilizer/sales/dealer-allocations/destroy'
export const dealerAllocationShow = '/fertilizer/sales/dealer-allocations/show/'
export const getAllocationInfo = '/fertilizer/sales/dealer-allocations/allocation-dealer-info'

// pos allocation api
export const posList = '/fertilizer/sales/pos/list'
export const posStore = '/fertilizer/sales/pos/store'
export const posUpdate = '/fertilizer/sales/pos/update'
export const posFinalSave = '/fertilizer/sales/pos/final-save'
export const posDestroy = '/fertilizer/sales/pos/destroy'
export const posShow = '/fertilizer/sales/pos/show/'
export const getDealerInfo = '/fertilizer/sales/pos/dealer-info/'
export const getAllocation = '/fertilizer/sales/pos/dealer-allocation-info'
export const getFertilizerInfo = '/fertilizer/sales/pos/dealer-fertilizer-info'

export const fertilizerDeliveryData = '/fertilizer/sales/fertilizer-delivery/pos-info'
export const fertilizerDeliveryList = '/fertilizer/sales/fertilizer-delivery/list'
export const fertilizerDeliveryStore = '/fertilizer/sales/fertilizer-delivery/store'

export const carryingAgentFinalProTransferAgent = ''
export const carryingAgentFinalProUpdate = ''
export const carryingAgentFinalProWise = ''
