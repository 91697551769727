<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
             <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="org_id">
                  {{ $t('fertilizerSales.ferti_delivery')}}
                </list-report-head>
              </b-col>
              </b-row>
              <b-row>
              <div class="row">
                <b-col lg="8" sm="8"></b-col>
                <b-col lg="4" sm="4">
                  <b-table-simple borderless style="font-size:12px;" class=" remove-margin-padding">
                    <tbody>
                      <tr>
                        <th>{{ $t('fertilizerSales.first_copy') }}</th>
                        <th class="text-center">:</th>
                        <td>{{ $t('fertilizerSales.dealer') }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('fertilizerSales.second_copy') }}</th>
                        <th class="text-center">:</th>
                        <td>{{ $t('fertilizerSales.godown') }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('fertilizerSales.third_copy') }}</th>
                        <th class="text-center">:</th>
                        <td>{{ $t('fertilizerSales.office') }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>
                </b-col>
                <b-col lg="7" sm="7">
                  <b-table-simple borderless style="font-size:12px;" class=" remove-margin-padding">
                    <b-tbody>
                      <b-tr>
                          <td style="width: 40%">{{ $t('globalTrans.date') }}</td>
                          <td class="text-center"  style="width: 5%">:</td>
                          <td  style="width: 55%">{{ formData.sell_date | dateFormat }}</td>
                      </b-tr>
                      <b-tr>
                          <td>{{ $t('fertilizerSales.memo_no') }} </td>
                          <td class="text-center">:</td>
                          <td>{{ $n(formData.memo_no, { useGrouping: false }) }}</td>
                      </b-tr>
                      <b-tr>
                          <td>{{ $t('fertilizerSales.sales_center') }}</td>
                          <td class="text-center">:</td>
                          <td>{{ currentLocale === 'en' ? formData.sale_center : formData.sale_center_bn }}</td>
                      </b-tr>
                      <b-tr>
                          <td>{{ $t('fertilizerSales.godown') }} </td>
                          <td class="text-center">:</td>
                          <td>{{ $i18n.locale === 'en' ? formData.godown_name : formData.godown_name_bn }} </td>
                      </b-tr>
                      <b-tr>
                          <td>{{ $t('fertilizerSales.dealer_code') }}</td>
                          <td class="text-center">:</td>
                          <td>{{ formData.dealer_code }}</td>
                      </b-tr>
                      <tr>
                          <td>{{ $t('fertilizerSales.dealer_type') }}</td>
                          <td class="text-center">:</td>
                          <td>{{ formData.dealer_type_id === 1 ? $t('fertilizerSales.seeds') : $t('fertilizerSales.fertilizer') }}</td>
                      </tr>
                      <b-tr>
                          <td>{{ $t('fertilizerSales.dealer_name') }}</td>
                          <td class="text-center">:</td>
                          <td>{{ $i18n.locale === 'en' ? formData.dealer_name : formData.dealer_name_bn }}</td>
                      </b-tr>
                      <b-tr>
                          <td>{{ $t('fertilizerSales.trade_name') }}</td>
                          <td class="text-center">:</td>
                          <td>{{ $i18n.locale === 'en' ? formData.trade_name : formData.trade_name_bn  }}</td>
                      </b-tr>
                      <b-tr>
                      </b-tr>
                      <b-tr>
                          <td>{{ $t('fertilizerSales.dealer_licence') }}</td>
                          <td class="text-center">:</td>
                          <td>{{ formData.licence_number }}</td>
                      </b-tr>
                      <b-tr>
                          <td>{{ $t('fertilizerSales.issue_date') }}</td>
                          <td class="text-center">:</td>
                          <td>{{ formData.licence_issue_date | dateFormat }}</td>
                      </b-tr>
                      <b-tr style="padding-bottom: 50px">
                          <td>{{ $t('globalTrans.district') }}</td>
                          <td class="text-center">:</td>
                          <td>{{ $i18n.locale === 'en' ? formData.pre_district_id.text_en : formData.pre_district_id.text_bn }}</td>
                      </b-tr>
                      <b-tr style="padding-bottom: 50px">
                          <td>{{ $t('globalTrans.upazila') }}</td>
                          <td class="text-center">:</td>
                          <td>{{ $i18n.locale === 'en' ? formData.pre_upazilla_id.text_en : formData.pre_upazilla_id.text_bn }}</td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
                <b-col lg="5" sm="5">
                  <b-table-simple bordered  style="font-size:12px;" class="remove-margin-padding">
                    <tbody>
                      <b-tr>
                        <th colspan="4" class="text-center">{{ $t('fertilizerSales.dealer_alloc_info') }}</th>
                      </b-tr>
                      <b-tr>
                        <th class="text-center">{{ $t('fertilizerSales.fertilizer') }}</th>
                        <th class="text-center">{{ $t('fertilizerSales.allocated_amount') }}</th>
                        <th class="text-center">{{ $t('fertilizerSales.purchase_amount') }}</th>
                        <th class="text-center">{{ $t('fertilizerSales.rest_amount') }}</th>
                      </b-tr>
                      <tr v-for="(pay,i) in payHistoriesList" :key="i">
                        <td class="text-center"> {{ $i18n.locale === 'bn' ? pay.fertilizer_name_bn : pay.fertilizer_name }}</td>
                        <td class="text-right"> {{ $n(pay.allocation_amount, { useGrouping: false }) }}</td>
                        <td class="text-right"> {{ $n(pay.purchase_amount, { useGrouping: false }) }}</td>
                        <td class="text-right"> {{ $n(pay.rest_amount, { useGrouping: false }) }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>
                </b-col>
                <div class="col text-right pr-0">
                  <span class="text-right text-dark font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                </div>
                <b-table-simple class="mt-3 remove-margin-padding" hover small bordered caption-top responsive >
                  <b-thead>
                      <b-tr>
                      <th rowspan="2" class="text-center">{{ $t('fertilizerSales.fertilizer') }}</th>
                      <th rowspan="2" class="text-center">{{ $t('fertilizerSales.source_country') }}</th>
                      <th colspan="2" class="text-center">{{ $t('fertilizerSales.purchase_amount') }}</th>
                      <th rowspan="2" class="text-center">{{ $t('fertilizerSales.rate_per_ton') }}</th>
                      <th rowspan="2" class="text-center">{{ $t('fertilizerSales.ferti_purchase_price') }}</th>
                      <th colspan="4" class="text-center">{{ $t('fertilizerSales.money_recieve') }}</th>
                      <th rowspan="2" class="text-center">{{ $t('fertilizerSales.comment') }}</th>
                    </b-tr>
                    <b-tr>
                      <th class="text-center">{{  $t('fertilizerSales.bag_no') }}</th>
                      <th class="text-center">{{ $t('fertilizerSales.amount_ton') }}</th>
                      <th class="text-center">{{ $t('fertilizerSales.pay_order_no') }}</th>
                      <th class="text-center">{{ $t('globalTrans.date') }}</th>
                      <th class="text-center">{{ $t('fertilizerSales.bank') }} {{' & '}} {{ $t('fertilizerSales.branch') }}</th>
                      <th class="text-center">{{ $t('fertilizerSales.amount_of_money') }}</th>
                    </b-tr>
                    <b-tr>
                      <th class="text-center">{{ $n(1) }}</th>
                      <th class="text-center">{{ $n(2) }}</th>
                      <th class="text-center">{{ $n(3) }}</th>
                      <th class="text-center">{{ $n(4) }}</th>
                      <th class="text-center">{{ $n(5) }}</th>
                      <th class="text-center">{{ $n(6) }}</th>
                      <th class="text-center">{{ $n(7) }}</th>
                      <th class="text-center">{{ $n(8) }}</th>
                      <th class="text-center">{{ $n(9) }}</th>
                      <th class="text-center">{{ $n(10) }}</th>
                      <th class="text-center">{{ $n(11) }}</th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <td class="text-center">{{ $i18n.locale === 'bn' ? formData.fertilizer_bn : formData.fertilizer }}</td>
                      <td class="text-center"> {{ formData.source_country }} </td>
                      <td class="text-center"> {{ formData.amount_of_bags }} </td>
                      <td class="text-center"> {{ $n(formData.quantity, { useGrouping: false }) }} </td>
                      <td class="text-center"> {{ $n(formData.unit_price, { useGrouping: false }) }} </td>
                      <td class="text-center"> {{ $n(formData.quantity * formData.unit_price) }} </td>
                      <td class="text-center">
                        <span v-for="(item, index) in payOrdersList" :key="index"> {{ $n(item.pay_order_no, { useGrouping: false }) }} <br> </span>
                      </td>
                      <td class="text-center">
                        <span v-for="(item, index) in payOrdersList" :key="index"> {{ item.pay_order_date | dateFormat }} <br> </span>
                      </td>
                      <td class="text-center">
                        <span v-for="(item, index) in payOrdersList" :key="index"> {{ getBankName(item.bank_id) }} {{ '-' + getBranchName(item.branch_id) }} <br> </span>
                      </td>
                      <td class="text-center">
                        <span v-for="(item, index) in payOrdersList" :key="index"> {{ $n(item.amount, { useGrouping: false }) }} <br> </span>
                      </td>
                    <!--   <td> {{ payOrder.pay_order_no }} </td>
                      <td> {{ payOrder.pay_order_date }} </td>
                      <td> {{ payOrder.bank_id }}, {{ payOrder.branch_id }} </td>
                      <td> {{ payOrder.amount }} </td> -->
                      <td class="text-center"> {{ formData.comments }} </td>
                    </b-tr>
                    <tr>
                      <th colspan="4"></th>
                      <th class="text-right">{{ $t('globalTrans.total') }}</th>
                      <th colspan="6"> <span class="ml-4"> {{ $n(formData.quantity * formData.unit_price) }} </span></th>
                    </tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              </b-row>
              <br><br><br><br>
              <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple borderless style="font-size:12px;" class="remove-margin-padding">
                  <b-thead head-variant="secondary">
                      <b-tr>
                          <b-th colspan="3" class="text-center">{{ $t('movement.driverSignature') }}</b-th>
                          <b-th colspan="3" class="text-center">{{ $t('movement.BADCSignSeal') }}</b-th>
                          <b-th colspan="3" class="text-center">{{ $t('movement.CNFSignatureSeal') }}</b-th>
                          <b-th colspan="3" class="text-center">{{ $t('movement.receiverSignatureSeal') }}</b-th>
                      </b-tr>
                  </b-thead>
                </b-table-simple>
             </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.remove-margin-padding th, td {
  margin-top: 1px  !important;
  margin-bottom: 1px  !important;
  padding-top: 1px  !important;
  padding-bottom: 1px  !important;
  font-size: 12px;
}
</style>
<style scoped>
.table-bordered td, .table-bordered th {
  border: 1px solid #000 !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList } from '@/modules/seeds-fertilizer-service/fertilizer/movement/api/routes'
import ListReportHead from '@/components/custom/ListReportHeadFer.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      const tmp = this.getDetailsData()
      this.formData = tmp
      this.formData.source_country = tmp.point_of_sale.source_country
      this.formData.quantity = tmp.point_of_sale.quantity
      this.formData.amount_of_bags = tmp.point_of_sale.quantity * 20
      this.formData.total_price = tmp.point_of_sale.total_price
      this.formData.unit_price = tmp.point_of_sale.unit_price
      this.formData.comments = tmp.point_of_sale.comments
      this.formData.dealer_code = tmp.point_of_sale.dealer_code
      this.formData.sell_date = tmp.point_of_sale.sell_date
      // this.formData.trade_name = tmp.point_of_sale.dealer.trade_name
      this.payOrdersList = tmp.point_of_sale.pay_orders
      const payHistoriesList = tmp.point_of_sale.pay_histories.map(item => {
      const fertiListObj = this.fertilizerNameList.find(ferItem => ferItem.value === item.fertilizer_id)
        const fertilizerData = {
          fertilizer_name: typeof fertiListObj !== 'undefined' ? fertiListObj.text_en : '',
          fertilizer_name_bn: typeof fertiListObj !== 'undefined' ? fertiListObj.text_bn : ''
        }
        return Object.assign({}, item, fertilizerData)
      })
      this.payHistoriesList = payHistoriesList
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        allocation_date: '',
        memo_no: '',
        lot_no: '',
        fertilizer_id: '',
        fertilizer_name: '',
        bill_amount: '',
        total_allocated_amount: '',
        port_info: [],
        amount_of_bag: '',
        fiscal_year_id: '',
        godown_infos_id: '',
        godown_name: '',
        godown_name_bn: '',
        dealer_name: '',
        dealer_name_bn: '',
        issue_date: '',
        sell_center: '',
        sell_center_bn: '',
        comments: '',
        comments_bn: '',
        total_price: '',
        unit_price: '',
        quantity: '',
        source_country: '',
        sell_date: '',
        dealer_code: '',
        trade_name: ''
      },
      org_id: 3,
      totalAvailableAmount: 0,
      currentAvailableAmount: 0,
      lcList: [],
      lcInfo: {},
      portDetailsInfo: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      payHistoriesList: [],
      payOrdersList: []
    }
  },
  computed: {
    portList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    importCountryList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getBankName (Id) {
      const bank = this.$store.state.commonObj.bankObj.bankList.find(doc => doc.value === Id)
      if (typeof bank === 'undefined') {
        return ''
      } else {
        return this.$i18n.locale === 'bn' ? bank.text_bn : bank.text_en
      }
    },
    getBranchName (Id) {
      const bank = this.$store.state.commonObj.bankObj.branchList.find(doc => doc.value === Id)
      if (typeof bank === 'undefined') {
        return ''
      } else {
        return this.$i18n.locale === 'bn' ? bank.text_bn : bank.text_en
      }
    },
    getDetailsData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    pdfExport () {
      const reportTitle = this.$t('fertilizerSales.ferti_delivery')
      const bankList = this.$store.state.commonObj.bankObj.bankList
      const branchList = this.$store.state.commonObj.bankObj.branchList
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this, this.payHistoriesList, this.payHistoriesList, bankList, branchList, this.payOrdersList)
    }
  }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
