<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                   <b-col class="col-lg-6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('fertilizerSales.memo_no')"
                    label-for="memo_no"
                    >
                    <b-form-input
                        id="memo_no"
                        v-model="search.memo_no"
                        placeholder=""
                        ></b-form-input>
                    </b-form-group>
                   </b-col>
                    <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('globalTrans.from_date')"
                        label-for="from_date"
                        >
                        <b-form-input
                            class="fromDate"
                            v-model="search.from_date"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('globalTrans.to_date')"
                        label-for="to_date"
                        >
                        <b-form-input
                            class="toDate"
                            v-model="search.to_date"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-sm-2 col-md-6">
                        <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('fertilizerSales.ferti_delivery')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                        <!-- <b-button variant="danger" v-b-modal.modal-5>
                             {{  $t('globalTrans.excelImport') }}
                        </b-button> -->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(memo_no)="data">
                                          {{ $n(data.item.memo_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(delivery_date)="data">
                                          {{ data.item.delivery_date | dateFormat }}
                                        </template>
                                        <template v-slot:cell(lc_no)="data">
                                          {{ $t(data.item.lc_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(quantity)="data">
                                          {{ $n(data.item.point_of_sale.quantity, { useGrouping: false }) }}
                                        </template>
                                         <template v-slot:cell(lc_date)="data">
                                           {{ $n(parseInt(data.item.lc_date.split('-')[0])) + '-' +  $n(data.item.lc_date.split('-')[1]) + '-' + $n(data.item.lc_date.split('-')[2]) }}
                                           </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-5 variant=" iq-bg-warning mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="fas fa-eye"></i></b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-5 size="sm" @click="edit(data.item)">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
          <b-modal id="modal-5" size="xl" :title="$t('fertilizerSales.ferti_delivery')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
             {{ $t('fertilizerSales.ferti_delivery') }}
            <b-button variant="light" @click="pdfExport" class="float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
          <Details :id="editItemId" :key="editItemId" ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { fertilizerDeliveryList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form,
        Details
    },
    data () {
        return {
            search: {
                id: 0,
                lc_no: '',
                memo_no: '',
                from_date: '',
                to_date: ''
            },
            lcList: []
        }
    },
    computed: {
        cnfAgentList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.cnfAgentList
        },
        fertilizerNameList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
        },
        godownInfoList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
        },
        dealerBasicList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.dealerBasicList.filter(item => item.status === 1)
        },
        transportAgentList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
        },
        importCountryList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
        },
        formTitle () {
          return (this.editItemId === 0) ? this.$t('fertilizerSales.ferti_delivery') : this.$t('fertilizerSales.ferti_delivery')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('fertilizerSales.memo_no'), class: 'text-center' },
                { label: this.$t('fertilizerSales.delivery_date'), class: 'text-center' },
                { label: this.$t('fertilizerSales.godown'), class: 'text-center' },
                { label: this.$t('fertilizerSales.month'), class: 'text-center' },
                { label: this.$t('fertilizerSales.dealerName'), class: 'text-center' },
                { label: this.$t('fertilizerSales.fertilizer'), class: 'text-center' },
                { label: this.$t('fertilizerSales.amount_ton'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'memo_no' },
                { key: 'delivery_date' },
                { key: 'godown_name_bn' },
                { key: 'month' },
                { key: 'dealer_name_bn' },
                { key: 'fertilizer_bn' },
                { key: 'quantity' },
                { key: 'action' },
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'memo_no' },
                { key: 'delivery_date' },
                { key: 'godown_name' },
                { key: 'month' },
                { key: 'dealer_name' },
                { key: 'fertilizer' },
                { key: 'quantity' },
                { key: 'action' },
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
        flatpickr('.toDate', {})
    },
    methods: {
        changeStatusCustom (baseUrl, uri, item) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
              /** The following line commented as list is reloaded */
              // Store.dispatch('toggleStatus', item)
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        toggleStatusCustom (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.deleteConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        toggleStatusCustom2 (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        // finalSave (item) {
        //   this.toggleStatusCustom2(seedFertilizerServiceBaseUrl, carryingAgentFinalProFinalSave, item)
        // },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, fertilizerDeliveryList, params).then(response => {
                if (response.success) {
                    // this.$store.dispatch('setList', response.data.data)
                    // this.$store.dispatch('setList', response.data.data)
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList(data) {
          const listData = data.map(item => {
            const godownObj = this.godownInfoList.find(godownItem => godownItem.value === item.point_of_sale.from_godown_id)
            const dealerObj = this.dealerBasicList.find(godownItem => godownItem.value === item.point_of_sale.dealer_id)
            const posData = {}
            const dealerData = {}
            posData.memo_no = item.point_of_sale.memo_no
            posData.month = this.getMonth(item.point_of_sale.month)
            if(typeof godownObj !== 'undefined') {
              posData.godown_name = godownObj.text_en
              posData.godown_name_bn = godownObj.text_bn
              const saleCenterList = this.$store.state.commonObj.officeList.find(item => item.value === godownObj.sales_center_id)
              posData.sale_center = saleCenterList !== undefined ? saleCenterList.text_en : ''
              posData.sale_center_bn = saleCenterList !== undefined ? saleCenterList.text_bn : ''
            } else {
              posData.godown_name = ''
              posData.godown_name_bn = ''
              posData.sale_center = ''
              posData.sale_center_bn = ''
            }

            if(typeof dealerObj !== 'undefined') {
              dealerData.sales_center_details = dealerObj.sales_center_details
              dealerData.sales_center_details_bn = dealerObj.sales_center_details_bn
              dealerData.dealer_name = dealerObj.text_en
              dealerData.dealer_name_bn = dealerObj.text_bn
              dealerData.trade_name = dealerObj.trade_name
              dealerData.trade_name_bn = dealerObj.trade_name_bn
              dealerData.licence_number = dealerObj.licence_number
              dealerData.licence_issue_date = dealerObj.licence_issue_date
              dealerData.pre_district_id = this.$store.state.commonObj.districtList.find(doc => doc.value === dealerObj.pre_district_id)
              dealerData.pre_upazilla_id = this.$store.state.commonObj.upazilaList.find(doc => doc.value === dealerObj.pre_upazilla_id)
            } else {
              dealerData.dealer_name = ''
              dealerData.dealer_name_bn = ''
              dealerData.trade_name = ''
              dealerData.trade_name_bn = ''
              dealerData.licence_number = ''
              dealerData.licence_issue_date = ''
              dealerData.pre_district_id = ''
              dealerData.pre_upazilla_id = ''
              dealerData.sales_center_details = ''
              dealerData.sales_center_details_bn = ''
            }

            const fertilizerObj = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(doc => doc.value === item.fertilizer_id)
            const fertilizerData = {}
            if(typeof fertilizerObj !== 'undefined') {
              fertilizerData.fertilizer = fertilizerObj.text_en
              fertilizerData.fertilizer_bn = fertilizerObj.text_bn
            } else {
              fertilizerData.fertilizer = ''
              fertilizerData.fertilizer_bn = ''
            }
            return Object.assign({}, item, posData, dealerData, fertilizerData)
          })
          return listData
        },
        getMonth (month) {
          month = parseInt(month)
          if (month === 1) {
            return this.$i18n.locale === 'bn' ? 'জুলাই' : 'July'
          } else if (month === 2) {
            return this.$i18n.locale === 'bn' ? 'আগস্ট' : 'August'
          } else if (month === 3) {
            return this.$i18n.locale === 'bn' ? 'সেপ্টেম্বর' : 'September'
          } else if (month === 4) {
            return this.$i18n.locale === 'bn' ? 'অক্টোবর' : 'October'
          } else if (month === 5) {
            return this.$i18n.locale === 'bn' ? 'নভেম্বর' : 'November'
          } else if (month === 6) {
            return this.$i18n.locale === 'bn' ? 'ডিসেম্বর' : 'December'
          } else if (month === 7) {
            return this.$i18n.locale === 'bn' ? 'জানুয়ারী' : 'January'
          } else if (month === 8) {
            return this.$i18n.locale === 'bn' ? 'ফেব্রুয়ারী' : 'February'
          } else if (month === 9) {
            return this.$i18n.locale === 'bn' ? 'মার্চ' : 'March'
          } else if (month === 10) {
            return this.$i18n.locale === 'bn' ? 'এপ্রিল' : 'April'
          } else if (month === 11) {
            return this.$i18n.locale === 'bn' ? 'মে' : 'May'
          } else if (month === 12) {
            return this.$i18n.locale === 'bn' ? 'জুন' : 'June'
          }
        },
         pdfExport () {
          this.$refs.details.pdfExport()
        }
    }
}
</script>
