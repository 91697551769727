import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

function getDataById (data, Id, vm) {
  data = data.find(item => item.value === Id)
  const name = typeof data !== 'undefined' ? data.text_en : ''
  const nameBn = typeof data !== 'undefined' ? data.text_bn : ''
  if (vm.$i18n.locale === 'en') {
    return name
  } else {
    return nameBn
  }
}
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, allocation, district, bankList, branchList, payOrders) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      const pdfContent = [
        {
            columns: reportHeadData.reportHeadColumn
        },
        {
          text: vm.$t('fertilizerReport.krishi_bhaban'),
          style: 'krishi',
          alignment: 'center'
        },
        { text: reportHeadData.address, style: 'header3', alignment: 'center' }
      ]

      if (reportHeadData.projectName) {
        pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
      }

      pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      pdfContent.push(
        {
          table: {
            style: 'tableData',
            headerRows: 0,
            widths: ['80%', '10%', '2%', '8%'],
            body: [
              [
                {},
                { text: vm.$t('fertilizerSales.first_copy'), style: 'td', alignment: 'right' },
                { text: ':', style: 'td', alignment: 'center' },
                { text: vm.$t('fertilizerSales.dealer'), style: 'td', alignment: 'left' }
              ],
              [
                {},
                { text: vm.$t('fertilizerSales.second_copy'), style: 'td', alignment: 'right' },
                { text: ':', style: 'td', alignment: 'center' },
                { text: vm.$t('fertilizerSales.godown'), style: 'td', alignment: 'left' }
              ],
              [
                {},
                { text: vm.$t('fertilizerSales.third_copy'), style: 'td', alignment: 'right' },
                { text: ':', style: 'td', alignment: 'center' },
                { text: vm.$t('fertilizerSales.office'), style: 'td', alignment: 'left' }
              ]
            ]
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        }
      )
      const rightTable = [
        [
          { text: vm.$t('fertilizerSales.dealer_alloc_info'), style: 'th', alignment: 'center', colSpan: 4 },
          {},
          {},
          {}
        ]
      ]
      var tabledata1 = [
        { text: vm.$t('movement.fertilizerName'), style: 'th', alignment: 'center' },
        { text: vm.$t('fertilizerSales.allocated_amount'), style: 'th', alignment: 'right' },
        { text: vm.$t('fertilizerSales.purchase_amount'), style: 'th', alignment: 'right' },
        { text: vm.$t('fertilizerSales.rest_amount'), style: 'th', alignment: 'right' }
      ]
      rightTable.push(tabledata1)
      allocation.map((item, index) => {
        tabledata1 = [
          { text: vm.$i18n.locale === 'bn' ? item.fertilizer_name_bn : item.fertilizer_name, style: 'td', alignment: 'center' },
          { text: vm.$n(item.allocation_amount), style: 'td', alignment: 'right' },
          { text: item.purchase_amount ? vm.$n(item.purchase_amount) : vm.$n(0), style: 'td', alignment: 'right' },
          { text: vm.$n(item.rest_amount), style: 'td', alignment: 'right' }
        ]
        rightTable.push(tabledata1)
      })
      pdfContent.push({
        table: {
          widths: ['60%', '40%'],
          body: [
              [
                {
                  table: {
                    widths: ['30%', '5%', '65%'],
                    body: [
                      [
                        { text: vm.$t('globalTrans.date'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: dateFormat(data.sale_date), style: 'td' }
                      ],
                      [
                        { text: vm.$t('fertilizerSales.memo_no'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: data.memo_no, style: 'td' }
                      ],
                      [
                        { text: vm.$t('fertilizerSales.sales_center'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: vm.$i18n.locale === 'bn' ? data.sales_center_details_bn : data.sales_center_details, style: 'td' }
                      ],
                      [
                        { text: vm.$t('fertilizerSales.godown'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: vm.$i18n.locale === 'bn' ? data.godown_name_bn : data.godown_name, style: 'td' }
                      ],
                      [
                        { text: vm.$t('fertilizerSales.dealer_code'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: data.dealer_code, style: 'td' }
                      ],
                      [
                        { text: vm.$t('fertilizerSales.dealer_type'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: (data.dealer_type_id === 1) ? vm.$t('fertilizerSales.seeds') : vm.$t('fertilizerSales.fertilizer'), style: 'td' }
                      ],
                      [
                        { text: vm.$t('fertilizerSales.trade_name'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? data.trade_name : data.trade_name_bn, style: 'td' }
                      ],
                      [
                        { text: vm.$t('fertilizerReport.ownerName'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? data.dealer_name : data.dealer_name_bn, style: 'td' }
                      ],
                      [
                        { text: vm.$t('fertilizerSales.dealer_licence'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: data.licence_number, style: 'td' }
                      ],
                      [
                        { text: vm.$t('fertilizerSales.issue_date'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: dateFormat(data.licence_issue_date), style: 'td' }
                      ],
                      [
                        { text: vm.$t('globalTrans.district'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? data.pre_district_id.text_en : data.pre_district_id.text_bn, style: 'td' }
                      ],
                      [
                        { text: vm.$t('globalTrans.upazila'), style: 'th' },
                        { text: ':', style: 'th' },
                        { text: vm.$i18n.locale === 'en' ? data.pre_upazilla_id.text_en : data.pre_upazilla_id.text_bn, style: 'td' }
                      ]
                    ]
                  },
                  layout: {
                    hLineWidth: function (i, node) {
                      return 0
                    },
                    vLineWidth: function (i, node) {
                      return 0
                    }
                  }
                },
                {
                  table: {
                    headerRows: 1,
                    widths: ['25%', '25%', '25%', '25%'],
                    body: rightTable
                  }
                }
              ]
            ]
        },
        layout: {
          hLineWidth: function (i, node) {
              return 0
          },
          vLineWidth: function (i, node) {
              return 0
          }
        }
      })
      pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })
      var allRow1 = []
      var tabledata = [
          { text: vm.$t('movement.fertilizerName'), style: 'th', rowSpan: '2', alignment: 'center' },
          { text: vm.$t('fertilizerSales.source_country'), style: 'th', rowSpan: '2', alignment: 'center' },
          { text: vm.$t('fertilizerSales.quantity'), style: 'th', colSpan: '2', alignment: 'center' },
          { },
          { text: vm.$t('fertilizerSales.rate_per_ton'), style: 'th', rowSpan: '2', alignment: 'center' },
          { text: vm.$t('fertilizerSales.ferti_purchase_price'), style: 'th', rowSpan: '2', alignment: 'center' },
          { text: vm.$t('fertilizerSales.money_recieve'), style: 'th', colSpan: '4', alignment: 'center' },
          { },
          { },
          { },
          { text: vm.$t('fertilizerSales.comment'), style: 'th', rowSpan: '2', alignment: 'center' }
      ]
      allRow1.push(tabledata)
      tabledata = [
        { },
        { },
        { text: vm.$t('fertilizerSales.bag_no'), style: 'th', alignment: 'center' },
        { text: vm.$t('fertilizerSales.amount_ton'), style: 'th', alignment: 'center' },
        { },
        { },
        { text: vm.$t('fertilizerSales.pay_orders'), style: 'th', alignment: 'center' },
        { text: vm.$t('globalTrans.date'), style: 'th', alignment: 'center' },
        { text: vm.$t('fertilizerSales.bank') + ' & ' + vm.$t('fertilizerSales.branch'), style: 'th', alignment: 'center' },
        { text: vm.$t('fertilizerSales.amount_of_money'), style: 'th', alignment: 'center' },
        { }
      ]
      allRow1.push(tabledata)
      let payOrder = ''
      let payOrderDate = ''
      let amountD = ''
      let bankInfo = ''
      payOrders.forEach((pay, index) => {
        const bank = getDataById(bankList, pay.bank_id, vm)
        const branch = getDataById(branchList, pay.branch_id, vm)
        if (index === 0) {
          payOrder = vm.$n(pay.pay_order_no, { useGrouping: false })
          amountD = vm.$n(pay.amount)
          payOrderDate = dateFormat(pay.pay_order_date)
          bankInfo = bank + '-' + branch
        } else {
          payOrder = payOrder + ',\n' + vm.$n(pay.pay_order_no, { useGrouping: false })
          amountD = amountD + ',\n' + vm.$n(pay.amount)
          payOrderDate = payOrderDate + ',\n' + dateFormat(pay.pay_order_date)
          bankInfo = bankInfo + ',\n' + bank + '-' + branch
        }
      })
      tabledata = [
        { text: vm.$i18n.locale === 'bn' ? data.fertilizer_bn : data.fertilizer, style: 'td', alignment: 'center' },
        { text: data.source_country, style: 'td', alignment: 'center' },
        { text: vm.$n(data.quantity * 20), style: 'td', alignment: 'center' },
        { text: vm.$n(data.quantity), style: 'td', alignment: 'center' },
        { text: vm.$n(data.unit_price), style: 'td', alignment: 'center' },
        { text: vm.$n(data.quantity * data.unit_price), style: 'td', alignment: 'center' },
        { text: payOrder, style: 'td', alignment: 'center' },
        { text: payOrderDate, style: 'td', alignment: 'center' },
        { text: bankInfo, style: 'td', alignment: 'center' },
        { text: amountD, style: 'td', alignment: 'center' },
        { text: vm.$i18n.locale === 'bn' ? data.comments_bn : data.comments, style: 'td', alignment: 'center' }
      ]
      allRow1.push(tabledata)

      tabledata = [
          { text: '', style: 'td', colSpan: '4', alignment: 'center' },
          { },
          { },
          { },
          { text: vm.$t('sitePreference.total'), style: 'td', alignment: 'right', bold: true },
          { text: vm.$n(data.quantity * data.unit_price), style: 'td', alignment: 'center', bold: true },
          { text: '', style: 'td', colSpan: '5', alignment: 'center', bold: true },
          { },
          { },
          { },
          { }
        ]
      allRow1.push(tabledata)
      pdfContent.push(
        {
          table: {
              headerRows: 2,
              margin: 0,
              style: 'header2',
              widths: '*',
              body: allRow1
          }
        }
      )
      pdfContent.push({ text: '', style: 'fertilizers', alignment: 'center' })
      pdfContent.push({ text: '', style: 'fertilizers', alignment: 'center' })
      pdfContent.push({ text: '', style: 'fertilizers', alignment: 'center' })
      var footer = []
      var footerData = [
          { text: vm.$t('fertilizerSales.godown_keeper'), style: 'th', alignment: 'center' },
          { text: vm.$t('fertilizerSales.sub_ast_engg'), style: 'th', alignment: 'center' },
          { text: vm.$t('fertilizerSales.ad'), style: 'th', alignment: 'center' },
          { text: vm.$t('fertilizerSales.dealer_signature'), style: 'th', alignment: 'center' }
        ]
      footer.push(footerData)

      pdfContent.push(
        {
          table: {
              headerRows: 0,
              margin: 0,
              style: 'header2',
              widths: '*',
              body: footer
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        }
      )
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'Landscape',
      watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [1, 1, 1, 1]
        },
        td: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [1, 1, 1, 1]
        },
        fertilizers: {
          fontSize: 10,
          margin: [10, 10, 0, 10]
        },
        krishi: {
          margin: [0, -5, 0, 5],
          alignment: 'center'
        },
        header: {
          fontSize: 12,
          margin: [0, 0, 0, 4]
        },
        header2: {
          border: 0,
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        fertilizer: {
          fontSize: 10,
          margin: [5, 10, 0, 5]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 0]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        header3: {
        fontSize: 9,
        margin: [0, 0, 0, 4]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).download('fertilizer-delivery')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
